import { useContext, useEffect } from 'react'

import { HeaderContext } from 'src/hooks/header/context'


const Setting = ({ hasBack }) => {

    const { setHasBack } = useContext(HeaderContext)

    useEffect(() => {
        setHasBack(hasBack)
    }, [hasBack, setHasBack])

    return null
}

export default Setting